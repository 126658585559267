// hooks/useEfficiencyData.js
import { useState, useEffect } from 'react';
import { fetchItemsByCategory } from '../services/LostArkApi';
import { useCrystalPrice } from './useCrystalPrice';

export const useEfficiencyData = () => {
  const categoryData = [
    {
      title: '4T 식물채집(만생기 기준)',
      code: 90200,
      itemCounts: { '들꽃': 2970, '수줍은 들꽃': 1423, '화사한 들꽃': 332, '아비도스 들꽃': 313 },
      timeWithLeap: 12.5,
      timeWithoutLeap: 25,
    },
    {
      title: '4T 벌목(만생기 기준)',
      code: 90300,
      itemCounts: { '목재': 2860, '부드러운 목재': 1164, '튼튼한 목재': 271, '아비도스 목재': 302 },
      timeWithLeap: 4.5,
      timeWithoutLeap: 9,
    },
    {
      title: '4T 채광(만생기 기준)',
      code: 90400,
      itemCounts: { '철광석': 2551, '묵직한 철광석': 991, '단단한 철광석': 248, '아비도스 철광석': 312 },
      timeWithLeap: 11,
      timeWithoutLeap: 22,
    },
    {
      title: '4T 수렵(만생기 기준)',
      code: 90500,
      itemCounts: { '두툼한 생고기': 2500, '다듬은 생고기': 1325, '진귀한 가죽': 285, '아비도스 두툼한 생고기': 247 },
      timeWithLeap: 11,
      timeWithoutLeap: 22,
    },
    {
      title: '4T 낚시(만생기 기준)',
      code: 90600,
      itemCounts: { '생선': 1999, '붉은 살 생선': 1111, '아비도스 태양 잉어': 315 },
      timeWithLeap: 24,
      timeWithoutLeap: 48,
    },
    {
      title: '4T 고고학(만생기 기준)',
      code: 90700,
      itemCounts: { '고대 유물': 2715, '희귀한 유물': 1309, '진귀한 유물': 325, '아비도스 유물': 377 },
      timeWithLeap: 7,
      timeWithoutLeap: 14,
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { crystalPrice, loading: crystalLoading, error: crystalError } = useCrystalPrice();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allData = await Promise.all(
          categoryData.map(async (category) => {
            const itemsFromApi = await fetchItemsByCategory(category.code);

            const processedItems = Object.keys(category.itemCounts).map((itemName) => {
              const apiItem = itemsFromApi.find((item) => item.Name === itemName);
              const count = category.itemCounts[itemName];
              const pricePerHundred = apiItem?.RecentPrice || 0;
              const totalGold = Math.floor((pricePerHundred * count) / 100);

              return {
                name: itemName,
                icon: apiItem?.Icon || '',
                count,
                pricePerHundred,
                totalGold,
              };
            });

            const totalGoldWithoutLeap = Math.floor(processedItems.reduce((sum, item) => sum + item.totalGold, 0));
            const totalGoldWithLeap = totalGoldWithoutLeap - Math.floor(crystalPrice / 3);
            const goldPerHour = Math.floor((totalGoldWithLeap / category.timeWithLeap) * 60); // 수정된 부분

            return {
              title: category.title,
              items: processedItems,
              timeWithLeap: category.timeWithLeap,
              timeWithoutLeap: category.timeWithoutLeap,
              totalGoldWithLeap,
              totalGoldWithoutLeap,
              goldPerHour,
            };
          })
        );

        setData(allData);
        setError(null);
      } catch (err) {
        setError('데이터를 불러오는 중 오류가 발생했습니다.');
      } finally {
        setLoading(false);
      }
    };

    // crystalLoading이 끝난 후 데이터를 가져옵니다.
    if (!crystalLoading) {
      setLoading(true); // fetchData 실행 전 로딩 상태 설정
      fetchData();
    }
  }, [crystalPrice, crystalLoading]);

  return { data, loading: loading || crystalLoading, error: error || crystalError };
};
