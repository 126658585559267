import React, { useState } from 'react';
import EfficiencyTable from '../components/EfficiencyTable';
import '../styles/EfficiencyPage.css';
import Header from '../components/Header';
import { useEfficiencyData } from '../hooks/useEfficiencyData';

const EfficiencyPage = () => {
  const { data: tables, loading, error } = useEfficiencyData();
  const [sorted, setSorted] = useState(false); // 정렬 상태 관리
  const [sortedData, setSortedData] = useState([]); // 정렬된 데이터 상태

  if (loading) return <p>로딩 중...</p>;
  if (error) return <p style={{ color: 'red' }}>{error}</p>;

  const toggleSort = () => {
    if (sorted) {
      // 원래 데이터 복원
      setSorted(false);
      setSortedData(tables);
    } else {
      // goldPerHour 값으로 내림차순 정렬
      const sortedTables = [...tables].sort((a, b) => b.goldPerHour - a.goldPerHour);
      setSorted(true);
      setSortedData(sortedTables);
    }
  };

  const displayedData = sorted ? sortedData : tables; // 정렬 상태에 따른 데이터 선택

  return (
    <div className="efficiency-page">
      <Header />
      <h1>생활 효율 계산기</h1>
      <button onClick={toggleSort} className="sort-button">
        {sorted ? '원래대로' : '시간당 골드 정렬'}
      </button>
      {displayedData.map((table, index) => (
        <EfficiencyTable
          key={index}
          title={table.title}
          items={table.items}
          timeWithLeap={table.timeWithLeap}
          totalGoldWithLeap={table.totalGoldWithLeap}
          timeWithoutLeap={table.timeWithoutLeap}
          totalGoldWithoutLeap={table.totalGoldWithoutLeap}
          goldPerHour={table.goldPerHour}
        />
      ))}
    </div>
  );
};

export default EfficiencyPage;
