// services/LostArkApi.js
import axios from 'axios';

const BACKEND_API_URL = 'https://arklife.shop'; // 백엔드 서버 URL

export const fetchItemsByCategory = async (categoryCode) => {
  try {
    const response = await axios.get(`${BACKEND_API_URL}/items/${categoryCode}`);
    console.log(`Fetched items for category ${categoryCode} from backend:`, response.data); 
    return response.data?.Items || []; 
  } catch (error) {
    console.error('아이템 목록을 가져오는 중 오류 발생:', error);
    return [];
  }
};

export const fetchCrystalPrice = async () => {
  try {
    const response = await axios.get(`${BACKEND_API_URL}/api/crystal`);
    console.log('Fetched crystal price from backend:', response.data);
    return response.data?.crystal_price || 0; // 크리스탈 시세 데이터 반환
  } catch (error) {
    console.error('크리스탈 시세를 가져오는 중 오류 발생:', error);
    return 0;
  }
};
