// hooks/useCrystalPrice.js
import { useState, useEffect } from 'react';
import { fetchCrystalPrice } from '../services/LostArkApi';

export const useCrystalPrice = () => {
  const [crystalPrice, setCrystalPrice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const price = await fetchCrystalPrice(); // 크리스탈 시세 값 직접 가져오기
        setCrystalPrice(price);
      } catch (err) {
        setError('크리스탈 시세 데이터를 불러오는 중 오류가 발생했습니다.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { crystalPrice, loading, error };
};
